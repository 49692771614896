<template>
  <section>
   
    <b-row class="match-height">
      <b-col md="6">
        <b-card no-body class="card-developer-meetup">
          <div class="bg-light-primary rounded-top text-center">
            <b-img
            :src="require('@/assets/images/logo/dashboard.png')"
              alt="Meeting Pic"
              height="150"
              width="390"
            />
          </div>
          <b-card-body>
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">Tgl</h6>
                <h3 class="mb-0">
                  {{ new Date().getDate() }}
                </h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25"> Hi, {{ nama }} </b-card-title>
                <b-card-text class="mb-0">
                  Kerjakan Ujian Sesuai dengan Jadwal
                </b-card-text>
              </div>
            </div>
            <!--/ metting header -->

            <!-- media -->
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="BookmarkIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body v-if="level == 0">
                <h6 class="mb-0">
                  <b>FREE TRIAL</b>
                </h6>
                <small
                  >Silahkan Upgrade Akun, untuk lebih banyak keuntungan</small
                >
              </b-media-body>
              <b-media-body v-else-if="level == 1">
                <h6 class="mb-0">
                  <b>PREMIUM</b>
                </h6>
                <small>Akun ini sudah melakukan Upgrade Akun</small>
              </b-media-body>
              <b-media-body v-if="level == 2">
                <h6 class="mb-0">
                  <b>ADMINISTRATOR</b>
                </h6>
                <small>Akun ini dikelola oleh Internal Team</small>
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="MailIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{ email }}</b>
                </h6>
                <small>Email yang digunakan untuk registrasi</small>
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="MapPinIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{ kampus }}</b>
                </h6>
                <small>Kampus yang digunakan untuk registrasi</small>
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{ hari_ini }}</b>
                </h6>
                <small>Tanggal dan Waktu Saat Ini</small>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-table-simple hover small caption-top responsive v-if="distance > 0">
          <colgroup>
            <col />
            <col />
          </colgroup>
          <colgroup>
            <col />
            <col />
            <col />
          </colgroup>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <b-thead head-variant="light-primary">
            <b-tr>
              <b-th colspan="4" class="text-center" style="color: red">
                Waktu Aktivasi
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class="text-center" variant="danger"><b>Hari</b></b-td>
              <b-td class="text-center" variant="danger"><b>Jam</b></b-td>
              <b-td class="text-center" variant="danger"><b>Menit</b></b-td>
              <b-td class="text-center" variant="danger"><b>Detik</b></b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-center" variant="danger">
                <h1>{{ days }}</h1>
              </b-td>
              <b-td class="text-center" variant="danger">
                <h1>{{ hours }}</h1>
              </b-td>
              <b-td class="text-center" variant="danger">
                <h1>{{ minutes }}</h1>
              </b-td>
              <b-td class="text-center" variant="danger">
                <h1>{{ seconds }}</h1>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple hover small caption-top responsive v-else>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="4" class="text-center"> Waktu Aktivasi </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td class="text-center" variant="danger"><b>Hari</b></b-td>
                <b-td class="text-center" variant="danger"><b>Jam</b></b-td>
                <b-td class="text-center" variant="danger"><b>Menit</b></b-td>
                <b-td class="text-center" variant="danger"><b>Detik</b></b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="danger">
                  <h1>00</h1>
                </b-td>
                <b-td class="text-center" variant="danger">
                  <h1>00</h1>
                </b-td>
                <b-td class="text-center" variant="danger">
                  <h1>00</h1>
                </b-td>
                <b-td class="text-center" variant="danger">
                  <h1>00</h1>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        <b-card class="card-transaction">
          <b-card-header style="padding-top: 0px; padding-left: 0px">
            <div class="d-flex align-items-center">
              <b-avatar rounded variant="light-danger" size="34" class="mr-1">
                <feather-icon icon="BellIcon" size="18" />
              </b-avatar>
              <b-card-title>
                <b style="color: red">Informasi Penting</b>
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body style="padding-top: 0px; padding-left: 0px">
            <app-timeline>
              <app-timeline-item
                variant="danger"
                title="Pastikan ujian sesuai waktu dan jadwal yang sudah ditentukan"
                style="padding-bottom: 14px"
              />
              <app-timeline-item
                variant="danger"
                title="Aktivasi Akun anda dengan Layanan Paket Kami"
                style="padding-bottom: 14px"
              />
              <app-timeline-item
                variant="danger"
                title="Gunakan Layanan Paket Kami untuk mendapatkan layanan yang maksimal"
                style="padding-bottom: 14px"
              />
              <app-timeline-item
                variant="danger"
                title="Cek Secara Berkala untuk Masa Berlaku Aktivasi pada Layanan Paket"
                style="padding-bottom: 14px"
              />
              <app-timeline-item
                variant="danger"
                title="Hubungi Team Kami, jika memiliki Pertanyaan atau Kendala Terkait Program"
                style="padding-bottom: 14px"
              />
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from 'moment'
import 'moment/locale/id'
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,moment,
    StatisticCardHorizontal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      nama: localStorage.getItem("Unama"),
      email: localStorage.getItem("Uemail"),
      level: localStorage.getItem("Ulevel"),
      kampus: localStorage.getItem("Ukampus"),
      id_session: localStorage.getItem("Uid"),
      nameMonth: "",
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      distance: 0,
      hari_ini: "",
      // VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL
    };
  },
  mounted() {
    this.checkAuth();
    this.getMonthName();
    moment.locale('id');
    this.hari_ini = moment().format('LLLL');
    this.getSanggahTime()
  },

  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
     getSanggahTime() {
      this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `users/search/${this.id_session}`
      )
        .then(response => {
          this.cek_user = response.data
          var getDeathTime = response.data.data["masa_berlaku"]
          this.id_death_time = getDeathTime
          var timecount = this.id_death_time;
          // var test = "Apr 28 2020 03:14:11";
          var vm = this
          // console.log("miki", response.data.data["masa_berlaku"])
          var x = setInterval(function () {
            var now = new Date().getTime();
            var countDownDate = new Date(timecount).getTime();
            vm.distance = countDownDate - now;
            vm.days = Math.floor(vm.distance / (1000 * 60 * 60 * 24));
            vm.hours = Math.floor((vm.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            vm.minutes = Math.floor((vm.distance % (1000 * 60 * 60)) / (1000 * 60));
            vm.seconds = Math.floor((vm.distance % (1000 * 60)) / 1000);
          }, 1000);
        }).catch((err) => {
          console.log(err)
        })
    },
    getMonthName() {
      // const date = new Date(); // 2009-11-10
      // const month = date.toLocaleString("default", { month: "long" });
      // this.nameMonth = month;

      const arrbulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const arrhari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum'at",
        "Sabtu",
      ];
      var date = new Date();
      var millisecond = date.getMilliseconds();
      var detik = date.getSeconds();
      var menit = date.getMinutes();
      var jam = date.getHours();
      var hari = date.getDay();
      var tanggal = date.getDate();
      var bulan = date.getMonth();
      var tahun = date.getFullYear();
      var time =
        arrhari[hari] +
        ", " +
        tanggal +
        " " +
        arrbulan[bulan] +
        " " +
        tahun +
        ", " +
        jam +
        " : " +
        menit +
        " : " +
        detik;
      this.nameMonth = time;
    },
  },
};
</script>
